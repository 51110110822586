import { FC } from 'react'
import Image from "next/legacy/image"
import cn from 'classnames'

interface PLPServicesProps {
  primary: {
    block_title?: string
    block_subtitle?: string
    background_color?: string
    padding_top?: boolean
  }
  items: [] | any
  applyWrapper?: boolean
}

const PLPServices: FC<PLPServicesProps> = ({ primary, items, applyWrapper = true }) => {
  const { block_title, block_subtitle, background_color, padding_top } = primary || {}

  const servicesQuantity = items?.length

  return (
    <div className={cn({ 'bg-ecru': !background_color })} style={{ backgroundColor: background_color }}>
      <div className={cn('pb-40 tablet:pb-80', { wrapper: applyWrapper, 'pt-40 tablet:pt-72': padding_top })}>
        <div>
          {block_subtitle && <div className="title text-moss text-center mb-8">{block_subtitle}</div>}
          {block_title && <h2 className="text-center heading-5 mb-52">{block_title}</h2>}
        </div>

        <div className={`block px-0 tablet:grid grid-cols-${servicesQuantity} gap-16 tablet:px-28`}>
          {items &&
            items.map((item: any, index: number) => {
              const { service_image, service_title, service_description } = item || {}

              return (
                <div
                  key={index}
                  className={cn({
                    'sliceContents mb-24 pb-28 tablet:mb-0 tablet:pb-0': index !== items?.length - 1,
                  })}
                >
                  <div className="flex justify-center">
                    {service_image?.url && (
                      <Image src={service_image?.url} alt="feature image" width={90} height={90} />
                    )}
                  </div>
                  {service_title && <h3 className="mt-16 mb-8 title  text-center">{service_title}</h3>}
                  {service_description && <div className="body text-center px-28">{service_description}</div>}
                </div>
              )
            })}
        </div>
      </div>

      <style jsx>{`
        @media (max-width: 375px) {
          .sliceContents {
            min-width: 249px;
          }
        }
      `}</style>
    </div>
  )
}

export default PLPServices
